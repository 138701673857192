<template>
  <div>
    <vs-card v-if="orders.length == 0">
      <div slot="header">
        <p>Não há requisições</p>
      </div>
    </vs-card>

    <vs-table
      ref="table"
      pagination
      :max-items="20"
      search
      :data="orders"
      v-if="orders.length > 0"
    >
      <template slot="thead">
        <vs-th sort-key="projectCode">Projeto</vs-th>
        <vs-th sort-key="type">Tipo</vs-th>
        <vs-th sort-key="createdAt">Solicitação</vs-th>
        <vs-th sort-key="date">Data solicitada</vs-th>
        <vs-th sort-key="projectName">Item</vs-th>
        <vs-th sort-key="qtd">Qtd</vs-th>
        <vs-th sort-key="phaseName">Status</vs-th>
        <vs-th>Unidade</vs-th>
        <vs-th>Ações</vs-th>
        <vs-th>Mais detalhes</vs-th>
      </template>
      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td
              :style="{ color: tr.classification.color }"
              :data="tr.projectCode"
              ><strong>{{ tr.projectCode }}</strong></vs-td
            >
            <vs-td :data="tr.type"> {{ tr.type }} </vs-td>
            <vs-td :data="tr.createdAt">
              {{ tr.created }}
            </vs-td>
            <vs-td :data="date">
              {{ tr.date ? tr.dateFormatted : "-" }}
            </vs-td>

            <vs-td :data="tr.projectName">
              {{ tr.projectName }}
              <strong v-if="tr.duplicated" class="duplicated"
                ><br />* Requisição duplicada! *</strong
              >
            </vs-td>
            <vs-td :data="tr.qtd">{{ tr.qtd }} </vs-td>
            <vs-td :data="tr.phaseName">
              {{ tr.phaseName }}
            </vs-td>
            <vs-td>
              <span v-if="tr.makeUnit"> {{ tr.makeUnit.name }}</span>
              <span v-if="tr.homologationUnit && !tr.makeUnit">{{
                tr.homologationUnit.name
              }}</span>
            </vs-td>

            <vs-td>
              <a href="#" @click="openConfirmScreen(tr)" v-if="tr.phase.id <= 3"
                >Cancelar</a
              >

              <a href="#" v-if="tr.phase.id == 14" @click="showCodeSreen(tr)"
                >Informar código de<br />
                reserva/transferência</a
              ></vs-td
            >
            <vs-td>
              <feather-icon
                icon="MessageCircleIcon"
                class="cursor-pointer"
                @click="showComments(tr.uuid)"
              />
              <feather-icon
                icon="ClipboardIcon"
                class="cursor-pointer"
                @click="showHistory(tr.uuid)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <vs-popup :active.sync="showScreenComments" title="Comentários">
      <p v-if="comments.length == 0">Não há comentários cadastrados</p>
      <div :key="index" v-for="(comment, index) in comments">
        <p>
          <strong>{{ comment.description }}</strong>
        </p>
        <p>
          <small
            >Postado por {{ comment.user.name }} em {{ comment.created }}</small
          >
        </p>
        <vs-divider></vs-divider>
      </div> </vs-popup
    >´

    <vs-popup :active.sync="showHistoryScreen" title="Histórico">
      <vs-table
        v-if="history.length > 0"
        ref="table"
        pagination
        :max-items="20"
        search
        :data="history"
      >
        <template slot="thead">
          <vs-th>Data</vs-th>
          <vs-th>Evento</vs-th>
          <vs-th>Fase</vs-th>
          <vs-th>Usuário</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                {{ tr.created }}
              </vs-td>
              <vs-td>
                {{ tr.event }}
              </vs-td>
              <vs-td> {{ tr.phase.name }} </vs-td>
              <vs-td>
                {{ tr.user.name }}
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </vs-popup>

    <vs-popup
      :active.sync="showPopUp"
      title="Código de reserva/transferência"
      v-if="orderSelected"
    >
      <p v-if="orderSelected.makeUnit">
        Informe o código de
        <span v-if="orderSelected.makeUnit.id == orderSelected.requestUnit.id"
          >Reserva</span
        ><span v-else>Transferência</span>
      </p>
      <p><vs-input name="code" v-model="code" type="number"></vs-input></p>
      <p><vs-button class="bt" @click="aprove()">Confirmar</vs-button></p>
    </vs-popup>
  </div>
</template>

    <script>
import siriusAPI from "../services";
import {
  notifyError,
  notifySuccess,
  formatDateToLocale,
} from "@/utils/helpers";
export default {
  data() {
    return {
      orders: [],
      showPopUp: false,
      orderSelected: null,
      code: null,
      showScreenComments: false,
      comments: [],
      showHistoryScreen: false,
      history: [],
    };
  },

  async created() {
    await this.loadOrders();
  },

  methods: {
    showHistory(uuid) {
      this.loadOrderHistory(uuid);
      this.showHistoryScreen = true;
    },
    async loadOrderHistory(uuid) {
      this.$vs.loading();
      await new siriusAPI()
        .getOrderHistory(uuid)
        .then((res) => {
          this.history = res.data.map((history) => ({
            ...history,
            created: formatDateToLocale(history.createdAt),
          }));
        })
        .catch((error) => {
          this.$vs.loading.close();
          if (error.response.data.statusCode == "401") {
            localStorage.clear();
            sessionStorage.removeItem("sirius-key");
            this.$router.push("/").catch(() => {});
            this.$acl.change(["public"]);
          } else {
            notifyError(
              this,
              "Não foi possível recuperar histórico da requisição! " + error
            );
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    showComments(uuid) {
      this.comments = [];
      this.$vs.loading();
      new siriusAPI()
        .getComments(uuid)
        .then((res) => {
          this.comments = res.data;
          this.comments = this.comments.map((comment) => ({
            ...comment,
            created: formatDateToLocale(comment.createdAt),
            updated: formatDateToLocale(comment.updatedAt),
          }));
        })
        .catch((error) => {
          this.$vs.loading.close();
          if (error.response.data.statusCode == "401") {
            localStorage.clear();
            sessionStorage.removeItem("sirius-key");
            this.$router.push("/").catch(() => {});
            this.$acl.change(["public"]);
          } else {
            notifyError(
              this,
              "Erro ao tentar carregar a lista de comentários: " + error
            );
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
      this.showScreenComments = true;
    },
    async getUnit(id) {
      let unit = "";
      await new siriusAPI()
        .getUnit(id)
        .then((res) => {
          unit = res.data.name;
        })
        .catch((error) => {
          if (error.response.data.statusCode == "401") {
            localStorage.clear();
            sessionStorage.removeItem("sirius-key");
            this.$router.push("/").catch(() => {});
            this.$acl.change(["public"]);
          } else {
            unit = "";
          }
        });
      return unit;
    },

    openConfirmScreen(order) {
      this.orderSelected = order;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmação de cancelamento`,
        text: "Deseja mesmo cancelar esta requisição?",
        acceptText: "Confirmar cancelamento",
        cancelText: "Desistir",
        accept: this.cancel,
      });
    },
    cancel() {
      const data = { phaseId: 10 }; //cancelar
      this.$vs.loading();
      new siriusAPI()
        .updatePhase(this.orderSelected.uuid, data)
        .then(() => {
          notifySuccess(this, "Requisição cancelada com sucesso");
          this.$vs.loading.close();
          this.loadOrders();
          this.orderSelected = null;
        })
        .catch((error) => {
          this.$vs.loading.close();
          if (error.response.data.statusCode == "401") {
            localStorage.clear();
            sessionStorage.removeItem("sirius-key");
            this.$router.push("/").catch(() => {});
            this.$acl.change(["public"]);
          } else {
            notifyError(this, "Erro ao tentar cancelar a requisição: " + error);
          }
        });
    },

    async showCodeSreen(order) {
      await this.getOrder(order.id);
      this.showPopUp = true;
    },
    aprove() {
      if (this.code) {
        if (
          this.orderSelected.makeUnit.id != this.orderSelected.requestUnit.id
        ) {
          this.orderSelected.transferCode = this.code;
        } else {
          this.orderSelected.reservationCode = this.code;
        }
        const data = {
          phaseId: 2,
          transferCode: this.orderSelected.transferCode,
          reservationCode: this.orderSelected.reservationCode,
          description: "",
        }; /*APROVADO */
        this.$vs.loading();

        new siriusAPI()
          .updatePhase(this.orderSelected.uuid, data)
          .then(() => {
            notifySuccess(this, "Requisição encaminhada com sucesso");
            this.$vs.loading.close();
            this.loadOrders();
            this.showPopUp = false;
            this.orderSelected = null;
            this.code = null;
          })
          .catch((error) => {
            this.$vs.loading.close();
            if (error.response.data.statusCode == "401") {
              localStorage.clear();
              sessionStorage.removeItem("sirius-key");
              this.$router.push("/").catch(() => {});
              this.$acl.change(["public"]);
            } else {
              this.code = null;
              notifyError(
                this,
                "Erro ao tentar avançar a requisição: " + error
              );
            }
          });
      } else {
        notifyError(this, "Obrigatório preencher o código para avançar!");
      }
    },

    async loadOrders() {
      this.$vs.loading();
      await new siriusAPI()
        .getOrders(false, false, false, true, "", "")
        .then((res) => {
          this.orders = res.data;
          this.orders = this.orders.map((order) => ({
            ...order,
            created: formatDateToLocale(order.createdAt),
            message: order.history[order.history.length - 1].description,
            lastUpdated: order.history[order.history.length - 1].user.name,
            necessityDate: formatDateToLocale(order.necessityAt),
            deliveryDate: formatDateToLocale(order.deliveryAt),
            type: order.inova ? "Desenvolvimento" : "Requisição",
            projectName: order.project.name,
            projectCode: order.code + " - " + order.classification.name,
            date: order.inova ? order.deliveryAt : order.necessityAt,
            dateFormatted: order.inova
              ? formatDateToLocale(order.deliveryAt)
              : formatDateToLocale(order.necessityAt),
            phaseName: order.phase.name,
          }));
        })
        .catch((error) => {
          this.$vs.loading.close();
          if (error.response.data.statusCode == "401") {
            localStorage.clear();
            sessionStorage.removeItem("sirius-key");
            this.$router.push("/").catch(() => {});
            this.$acl.change(["public"]);
          } else {
            notifyError(
              this,
              "Erro ao tentar carregar a lista de requisições " + error
            );
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    async getOrder(orderId) {
      this.$vs.loading();
      await new siriusAPI()
        .getOrder(orderId)
        .then((res) => {
          this.orderSelected = res.data;
        })
        .catch((error) => {
          this.$vs.loading.close();
          if (error.response.data.statusCode == "401") {
            localStorage.clear();
            sessionStorage.removeItem("sirius-key");
            this.$router.push("/").catch(() => {});
            this.$acl.change(["public"]);
          } else {
            notifyError(
              this,
              "Não foi possível recuperar informações da requisição!"
            );
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>
<style scoped>
.card {
  margin-bottom: 3em;
}
.border {
  border: 1px dotted #6666;
}
.bt {
  margin-right: 1em;
  margin-top: 1em;
}
.duplicated {
  color: red;
}
</style>
